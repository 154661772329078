import React, { useState, useEffect } from 'react';
import MovieList from './components/MovieList';
import MoodPicker from './components/MoodPicker';
import Loading from './components/Loading';
import './styles/app.css';
import { LOADING_MESSAGES, SUGGEST_API_URL } from './utils/constants';
import { IonIcon } from '@ionic/react';
import { bookmarkSharp, chevronBack, send } from 'ionicons/icons';
import { getSavedMovies } from './utils/indexdb';
import { sendEvent } from './utils/analytics';
import TopNav from './components/TopNav';
import LikedMovies from './components/LikedMovies';



const App = () => {
  const [movies, setMovies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [mood, setMood] = useState('');
  const [loadingMessage, setLoadingMessage] = useState('');
  const [viewStack, setViewStack] = useState(['moodPicker']);
  const [currentView, setCurrentView] = useState('moodPicker');
  const [showMore, setShowMore] = useState(true);
  const [likedMovies, setLikedMovies] = useState([]);

  const navigateTo = (view) => {
    if (currentView !== view) {
      setViewStack([...viewStack, view]);
    }
  }

  useEffect(() => {
    setCurrentView(viewStack[viewStack.length - 1]);
  }, [viewStack]);


  const handleMoviesFetched = (fetchedMovies) => {
    setMovies(fetchedMovies);
    setIsLoading(false);
    navigateTo('movieList');
  };

  const handleMoodPick = (mood) => {
    setError(false);
    setMood(mood);
    sendEvent('mood_picked', { mood })
    setIsLoading(true);
  }

  const handleOnError = (error) => {
    setError(error);
    setIsLoading(false);
    sendEvent('error', { mood: mood, error: JSON.stringify(error) });
    navigateTo('moodPicker');
  }

  useEffect(() => {
    let currentMessageIndex = 0;

    if (isLoading) {
      const randomMessage = LOADING_MESSAGES[Math.floor(Math.random() * LOADING_MESSAGES.length)];
      setLoadingMessage(randomMessage);
      const intervalId = setInterval(() => {
        currentMessageIndex = (currentMessageIndex + 1) % LOADING_MESSAGES.length;
        let message = LOADING_MESSAGES[currentMessageIndex];
        message = message.replace(/\{mood\}/g, mood)
        setLoadingMessage(message);
      }, 6000);

      // Clear the interval when the component is unmounted or when isLoading becomes false
      return () => clearInterval(intervalId);
    }
  }, [mood, isLoading]);

  const handleGoBack = () => {
    if (viewStack.length > 1) {
      const newViewStack = [...viewStack];
      newViewStack.pop();
      setViewStack(newViewStack);
      sendEvent('back_button_clicked', { view: currentView, mood: mood });
    }

    setIsLoading(false);
  }

  const handleShowMore = async () => {
    const movieTitles = movies.map(movie => movie.title);
    setShowMore(false);
    sendEvent('show_more_clicked', { mood });
    try {
      const response = await fetch(`${SUGGEST_API_URL}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          titles: movieTitles,
          mood: mood
        })
      });
      if (response.ok) {
        const data = await response.json();
        if (data.length > 0) {
          sendEvent('movies_fetched_more', { mood: mood, count: data.length, totalCount: movies.length + data.length });
          setMovies([...movies, ...data]);
          setShowMore(true);
        }
      } else {
        console.error('Request failed with status:', response.status);
        setShowMore(true);
      }
    } catch (error) {
      console.error(error);
      setShowMore(true);
    }
  };

  const handleLikeMovies = async () => {
    navigateTo('likedMovies');
    setLikedMovies("loading");
    sendEvent('liked_movies_viewed');
    let movies = await getSavedMovies();
    if (movies !== null && movies.length > 0) {
      movies.sort((a, b) => {
        const aTimestamp = a.timestamp || 0;
        const bTimestamp = b.timestamp || 0;
        return bTimestamp - aTimestamp; // For descending order
      });
      setLikedMovies(movies);
    } else {
      setLikedMovies([]);
    }
  }

  return (
    <div className='app'>

      <TopNav currentView={currentView} handleGoBack={handleGoBack} handleLikeMovies={handleLikeMovies} />

      <div className='container max-w-3xl flex flex-col px-6 py-1 text-slate-400 text-center'>

        {error &&
          <p className="error text-rose-700 p-4 fixed top-0 left-0 border-red-300 rounded-md bg-red-300 text-center">Oops! Mood meter's out. Try another vibe?</p>
        }

        {currentView === 'loading' &&
          <div className="loading-container flex flex-col justify-center pt-24 my-10 w-full">
            <div className="loading-spinner">
              <Loading />
            </div>
            <p className="loading-text self-center text-center my-24 w-full text-xl text-sky-200">{loadingMessage}</p>
          </div>
        }

        {currentView === 'moodPicker' &&
          <MoodPicker onMoodPick={(mood) => { handleMoodPick(mood); navigateTo('loading'); }} onMoviesFetched={handleMoviesFetched} onError={handleOnError} />
        }

        {currentView === 'movieList' &&
          <div className="movie-list-header flex flex-col items-center pt-10">
            <div className="current-mood pt-10 text-xl text-left w-full ml-2 text-sky-200">{mood}</div>
            <MovieList movies={movies} mood={mood} />
            <div className='show-more-button w-full mb-6'>
              <button className={` ${showMore ? 'bg-blue-800' : 'bg-gray-800'} hover:bg-blue-700 text-white w-full font-bold py-2 px-4 rounded`} onClick={handleShowMore} disabled={!showMore}>
                {showMore ? "Show More" : <Loading type="spinner" />}
              </button>
              <br /><br /><br />
            </div>
          </div>
        }

        {currentView === 'likedMovies' &&
          <LikedMovies likedMovies={likedMovies} />
        }

      </div>
    </div>
  );
};

export default App;
