// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDUYt23uWx9GPcOZP3Fblf7ah-oEWl_QwI",
    authDomain: "mopilot-c75c9.firebaseapp.com",
    projectId: "mopilot-c75c9",
    storageBucket: "mopilot-c75c9.appspot.com",
    messagingSenderId: "42655683796",
    appId: "1:42655683796:web:c9eb1c249a3978c349d741",
    measurementId: "G-QDXER340ZB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const sendEvent = (eventName, eventParams) => {
    logEvent(analytics, eventName, eventParams);
}

export { sendEvent }