// indexedDB.js
import { MD5 } from 'crypto-js';

const openDatabase = () => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open('movieDatabase', 1);

        request.onerror = function (event) {
            reject("Error opening database.");
        };

        request.onsuccess = function (event) {
            resolve(event.target.result);
        };

        request.onupgradeneeded = function (event) {
            const db = event.target.result;
            db.createObjectStore('movies');
        };
    });
};

const generateKey = (movie) => {
    return MD5(`${movie.title}-${movie.director}-${movie.releaseDate}`).toString();
};

const saveMovie = async (movie) => {
    const db = await openDatabase();
    const transaction = db.transaction(['movies'], 'readwrite');
    const store = transaction.objectStore('movies');
    movie.timestamp = Date.now(); // Add timestamp to movie object
    return store.add(movie, generateKey(movie));
};

const deleteMovie = async (movie) => {
    const db = await openDatabase();
    const transaction = db.transaction(['movies'], 'readwrite');
    const store = transaction.objectStore('movies');
    return store.delete(generateKey(movie));
};

const getSavedMovies = async () => {
    const db = await openDatabase();
    const transaction = db.transaction(['movies'], 'readonly');
    const store = transaction.objectStore('movies');
    const request = store.getAll();
    return new Promise((resolve, reject) => {
        request.onsuccess = function(event) {
            resolve(event.target.result);
        };

        request.onerror = function(event) {
            reject("Error retrieving movies.");
        };
    });
};

const isMovieSaved = async (movie) => {
    const db = await openDatabase();
    const transaction = db.transaction(['movies'], 'readonly');
    const store = transaction.objectStore('movies');
    const request = store.get(generateKey(movie));
  
    return new Promise((resolve, reject) => {
      request.onsuccess = function(event) {
        if (event.target.result) {
          resolve(true);
        } else {
          resolve(false);
        }
      };
  
      request.onerror = function(event) {
        reject("Error retrieving movie.");
      };
    });
  };

export { saveMovie, getSavedMovies, deleteMovie, isMovieSaved };