import Movie from "./Movie";


const MovieList = ({ movies, mood="" }) => {
  const movieMap = new Map();
  const orderedMovies = [];

  movies.forEach(movie => {
    const key = `${movie.title}-${movie.director}`;
    if (!movieMap.has(key)) {
      movieMap.set(key, movie);
      orderedMovies.push(movie);
    }
  });

  return (
    <div className="movies-list flex flex-col items-center pt-5 w-full">
      {orderedMovies.map((movie, index) => (
        <Movie key={index} movie={movie} index={index} mood={mood}/>
      ))}
    </div>
  );
};

export default MovieList;