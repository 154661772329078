import React from 'react';
import '../styles/loading.css';

const Loading = ({ type }) => {
    const className = type === 'spinner' ? 'loader-spinner' : 'loader';
    return (
        <div className={className}>
        </div>
    );
};

export default Loading;