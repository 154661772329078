const API_ENDPOINTS = {
    "local": {
        "suggest": "http://127.0.0.1:5001/mopilot-c75c9/us-central1/suggest",
        "searchTrailer": "http://127.0.0.1:5001/mopilot-c75c9/us-central1/searchTrailer"
    },
    "prod": {
        "suggest": '/api/suggest',
        "searchTrailer": '/api/searchTrailer'
    }
}

const hostname = window.location.hostname;


const isLocal = hostname.indexOf("localhost") > -1 || hostname.indexOf("127.0") > -1;

const SUGGEST_API_URL = isLocal ? API_ENDPOINTS.local.suggest : API_ENDPOINTS.prod.suggest;
const SEARCH_TRAILER_API_URL = isLocal ? API_ENDPOINTS.local.searchTrailer : API_ENDPOINTS.prod.searchTrailer;

const MOODS = [
    "Soothing movies for a cozy night in",
    "Edge-of-your-seat thrillers",
    "Laugh-out-loud comedies to lift my spirits",
    "Brainteasers that will make me think",
    "Romantic flicks to spark the feels",
    "Movie night with friends: action-packed!",
    "Family fun for everyone (age range)",
    "Date night: something steamy but not cheesy",
    "Solo indulgence: foreign films to get lost in",
    "Cinematic journey: visually stunning landscapes",
    "Hidden gems under the radar, any genre",
    "Similar to [movie title], but with a twist",
    "New releases I shouldn't miss",
    "Classic cinema, timeless masterpieces",
    "Guilty pleasure movies, don't judge!",
    "Surprise me! Recommend something completely unexpected.",
    "Movies to fuel my workout motivation",
    "Animated adventures for the inner child",
    "Documentaries that will change my perspective",
    "Short films that pack a punch",
    "Horror that creeps me out without nightmares",
    "Movies that explore societal issues",
    "Feel-good flicks to restore faith in humanity",
    "Sci-fi epics that take me to another world",
    "Movies with iconic soundtracks for a singalong",
    "Hidden gems from a specific decade or country",
    "Biopics that inspire and ignite passion",
    "Movies that make me question everything",
    "Independent films with raw storytelling",
    "Cult classics that deserve a revisit",
    "Animated musicals to tap your feet to",
    "Foreign films that showcase unique cultures",
    "Movies that redefine the concept of family",
    "Heartwarming stories about second chances",
    "Movies that teach valuable life lessons",
    "Suspenseful mysteries that keep me guessing",
    "Movies featuring strong female leads",
    "Mind-bending psychological thrillers",
    "Historical dramas that bring the past to life",
    "Movies with unforgettable villains",
    "Underrated gems that deserve more attention",
    "Movies based on true stories that will shock you",
    "Visually stunning masterpieces that are feasts for the eyes",
    "Movies that celebrate diversity and inclusion",
    "Unexpected twists and turns that will blow your mind",
    "Movies that make you laugh and cry in equal measure",
    "Thought-provoking conversations starters for movie night",
    "Movies that explore the human condition in all its complexity",
    "Inspiring stories of triumph over adversity",
    "Movies that will make you want to travel the world",
    "Character-driven dramas that delve into the depths of the soul",
    "Movies that challenge your perceptions and push boundaries",
    "Genre-bending films that defy expectations",
    "Adaptations that are better than the books",
    "Movies that will stay with you long after the credits roll",
    "Cinematic experiences that transport you to another world"
];

const LOADING_MESSAGES = [
    "Whipping up movie magic! Hang tight, it's worth the wait.",
    "Cue the popcorn! We're gathering the best picks for you.",
    "Patience, film buff! Handpicking your movies is an art, not a race.",
    "Hold on to your seats! Crafting the perfect movie list just for you.",
    "In a mood? Great choice! We're digging up some gems, stay tuned.",
    "Setting the stage! Give us a moment to raise the curtain.",
    "Almost there! Fine-tuning your movie experience.",
    "Let's get reel! Preparing a worthy movie selection takes a minute.",
    "Hang in there! We're tuning our movie radar to your mood.",
    "Browsing the archives for you! Good things come to those who wait."
  ];




export { SUGGEST_API_URL, SEARCH_TRAILER_API_URL, MOODS, LOADING_MESSAGES };