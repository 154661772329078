import React, { useEffect, useState } from 'react';
import { MOODS, SUGGEST_API_URL } from '../utils/constants';
import { sendEvent } from '../utils/analytics';



const numMoodsToShow = 20;

const MoodPicker = ({ onMoodPick, onMoviesFetched, onError }) => {
    const [mood, setMood] = useState('');
    const [isFetching, setIsFetching] = useState(false);
    const [pickedMoods, setPickedMoods] = useState([]);
    const [shouldShake, setShouldShake] = useState(false);
    const moods = MOODS


    useEffect(() => {
        setPickedMoods([...moods].sort(() => 0.5 - Math.random()).slice(0, numMoodsToShow));
    }, []);

    const fetchMovies = async () => {

        if (isFetching) {
            console.log(`Currently fetching data for mood= " + ${mood}. Please wait for request to finish before making another request. `);
            return;
        }


        if (!mood) {
            setShouldShake(true);
            setTimeout(() => setShouldShake(false), 820); // Reset after the duration of the shake animation
            return;
        }

        onMoodPick(mood);

        try {
            setIsFetching(true);
            const response = await fetch(`${SUGGEST_API_URL}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ mood })
            });
            if (!response.ok) {
                sendEvent('movies_fetch_error', { mood: mood, error: `Network response was not ok` });
                throw new Error('Network response was not ok');
            }
            const movies = await response.json();
            sendEvent('movies_fetched', { mood: mood, count: movies.length });
            onMoviesFetched(movies);
            setMood('');
            setIsFetching(false);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setIsFetching(false);
            setMood('');
            onError(error)

        }
    };

    return (
        <div className="mood-picker flex flex-col justify-between h-90">
            <h2 className="mood-heading pt-10 pb-2 mt-5 text-left">
                <p className='mt-5 text-xl text-sky-200 font-semibold'> Share your mood, find your film.</p>
                <p className='text-sm mt-2'> Say goodbye to endless browsing. Mopilot uses your mood to find you the right movie</p>
            </h2>

            <div className="custom-mood">
                <div className="relative flex py-2 h-36">
                    <textarea
                        className={`w-full h-full px-3 py-2 text-white placeholder-gray-500 bg-fill-primary border border-gray-600 rounded-md focus:outline-none focus:border-sky-500 ${shouldShake && 'animate-shake border-2 border-rose-600'}`}
                        rows="3"
                        placeholder="Im in the mood for a..."
                        onChange={(e) => setMood(e.target.value)}
                        onClick={() => setMood(mood.length > 0 ? mood.trim() + " " : mood)}
                        value={mood}
                    />

                </div>
            </div>

            <div className="mood-list flex overflow-x-auto py-2.5 text-sm scrollbar-hide">

                {pickedMoods.slice(0, numMoodsToShow).map(m => (
                    <div
                        key={m}
                        className={`flex cursor-pointer mood-button select-none border text-white text-left bg-gray-800 flex-none w-9/12 h-16 mr-4 rounded-md pl-3 pt-3 ${mood === m ? 'border-sky-500' : 'border-gray-800'}`}
                        onClick={() => setMood(m)}
                    >
                        {m}
                    </div>
                ))}

                <br /><br />
            </div>

            <div className='submit-mood self-center flex py-3 w-full items-center justify-center'>
                <button
                    className={`flex flex-row items-center justify-center w-full right-1 bottom-1 py-2 px-2 rounded-md hover:bg-gray-600 ${mood ? 'bg-rose-600 text-white' : 'bg-gray-800 text-white-600'}`}
                    onClick={() => fetchMovies()}
                >
                    <span className="mr-2 w-full">
                        Lets go!
                    </span>
                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 24 24" fill="none">
                        <path opacity="0.5" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#1C274C" />
                        <path d="M15.4137 13.059L10.6935 15.8458C9.93371 16.2944 9 15.7105 9 14.7868V9.21316C9 8.28947 9.93371 7.70561 10.6935 8.15419L15.4137 10.941C16.1954 11.4026 16.1954 12.5974 15.4137 13.059Z" fill="#1C274C" />
                    </svg> */}
                </button>
            </div>



        </div>
    );
};

export default MoodPicker;
