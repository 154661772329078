import React from 'react';
import { IonIcon } from '@ionic/react';
import { chevronBack, bookmarkSharp } from 'ionicons/icons';

const TopNav = ({ currentView, handleGoBack, handleLikeMovies }) => {
  return (
    <div className='top-nav fixed flex items-center justify-between select-none shadow-md text-sm  bg-neutral-800 left-0 top-0 z-10 drop-shadow-md py-2 flex-row w-full text-white'>

      <div className='w-16' onClick={handleGoBack}>
        {(currentView === 'movieList' || currentView === "likedMovies") &&
          <div className='back-button cursor-pointer p-2 rounded-md ml-6 hover:bg-gray-600 flex content-center items-center'>
            <IonIcon icon={chevronBack} className='text-xl' />
          </div>
        }
      </div>

      <div className='text-2xl text-sky-100 font-bold'>Mopilot</div>

      <div className='w-16 flex content-center items-center'>
        <div className='cursor-pointer p-2 text-xl rounded-md hover:bg-gray-600'
          onClick={handleLikeMovies}>
          <IonIcon icon={bookmarkSharp} className='text-xl text-rose-600 fill-rose-600' />
        </div>
      </div>

    </div>
  );
};

export default TopNav;