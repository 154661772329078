// Movie component

import { useState, useEffect, useRef } from "react";
import '../styles/movie.css';

import Loading from "./Loading";
import { SEARCH_TRAILER_API_URL } from "../utils/constants";
import { IonIcon } from "@ionic/react";
import { bookmarkSharp, logoYoutube } from "ionicons/icons";
import { deleteMovie, isMovieSaved, saveMovie } from "../utils/indexdb";
import { sendEvent } from "../utils/analytics";
import YouTube from 'react-youtube';

const Movie = ({ movie, index, mood = "" }) => {
  const [isHeartFilled, setHeartFilled] = useState(false);
  const [trailerUrl, setTrailerUrl] = useState(null);
  const [fetchFailed, setFetchFailed] = useState(false);
  const movieTrailerRef = useRef(null);
  const [player, setPlayer] = useState(null);

  const onPlayerReady = (event) => {
    setPlayer(event.target);
  };



  const fetchTrailer = async (attempts = 3) => {
    if (movie.trailerUrl && movie.trailerUrl.length > 3) {
      setTrailerUrl(movie.trailerUrl);
      return;
    }
    try {
      const response = await fetch(`${SEARCH_TRAILER_API_URL}?movieTitle=${encodeURIComponent(movie.title)}`);
      const data = await response.json();
      setTrailerUrl(data.trailerUrl);
    } catch (error) {
      if (attempts > 1) {
        fetchTrailer(attempts - 1);
      } else {
        setFetchFailed(true);
      }
    }
  };

  

  const handleHeartClick = async () => {
    if (isHeartFilled) {
      try {
        await deleteMovie(movie);
        setHeartFilled(false);
        sendEvent('removed_movie_bookmarked', { title: movie.title, director: movie.director, releaseDate: movie.releaseDate });
      } catch (error) {
        console.error('Failed to delete movie:', error);
      }
    } else {
      try {
        await saveMovie(movie);
        setHeartFilled(true);
        sendEvent('movie_bookmarked', { title: movie.title, director: movie.director, releaseDate: movie.releaseDate });
      } catch (error) {
        console.error('Failed to save movie:', error);
      }
    }
  };

  useEffect(() => {
    const checkIfMovieIsSaved = async () => {
      const saved = await isMovieSaved(movie);
      setHeartFilled(saved);
    };

    checkIfMovieIsSaved();
  }, [movie]);

  const convertMinutesToHours = (minutes) => {
    if (minutes === null || minutes === undefined || minutes === "") {
      return "";
    }
    
    if (minutes < 60) {
      return `${minutes}m`;
    } else if (minutes === 60) {
      return `1h`;
    } else {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      if (remainingMinutes === 0) {
        return `${hours}h`;
      } else {
        return `${hours}h ${remainingMinutes}m`;
      }
    }
  }

  useEffect(() => {
    // Set a delay of "index" seconds before fetching the trailer
    let timeoutSeconds = index + 1;
    if (timeoutSeconds > 5) {
      timeoutSeconds = Math.floor(Math.random() * 3) + 1; // random number between 1 and 3
    }
    const timeoutId = setTimeout(fetchTrailer, timeoutSeconds * 1000);
    // Clear the timeout if the component is unmounted before the timeout finishes
    return () => clearTimeout(timeoutId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onPlayerStateChange = (event) => {
    if (event.data === 1) { // If the new state is 'playing', send an event to your analytics.
      sendEvent('trailer_played', { mood: mood, title: movie.title, director: movie.director, releaseDate: movie.releaseDate });
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.intersectionRatio < 0.5) {
            try {
              if (player != null)
              player.pauseVideo();
            } catch (error) {
              console.error('Failed to pause video:', error, player);
            }
            
          }
        },
        {
          threshold: 0.5,
        }
      );
    
      if (movieTrailerRef.current) {
        observer.observe(movieTrailerRef.current);
      }
    }
  };



  const onTrailerError = (event) => {
    console.error('Error loading trailer:', event, movie);
    sendEvent('trailer_load_error', { mood: mood, error: JSON.stringify(event.data), index: index, title: movie.title, director: movie.director, releaseDate: movie.releaseDate });
    //setFetchFailed(true);
  }

  const opts = {
    height: '100%',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return (
    <div className="movie bg-gray-800 text-white p-4 rounded drop-shadow-md text-left p-10 mb-8 relative w-full fade-in">
      <div className="heart top-6 right-6 absolute w-8 h-8 cursor-pointer" onClick={handleHeartClick}>
        <div className='text-xl text-center'>
          <IonIcon icon={bookmarkSharp} className={isHeartFilled ? 'fill-rose-600 text-rose-600 active' : ''}>
          </IonIcon>
        </div>
      </div>
      <h2 className="movie-title text-xl w-10/12 font-bold mb-2">{movie.title}</h2>
      <p className="movie-year text-gray-300">{movie.releaseDate.split('-')[0]} &#x2022;  {convertMinutesToHours(movie.runtime)}</p>
      {/* <p className="movie-genre text-gray-300">{movie.genre}</p> */}
      <p className="movie-ratings text-sky-500">IMDb: {movie.imdbRating}  &#x2022; Rotten Tomatoes: {movie.rtRating}</p>
      <p className="movie-director text-gray-300 pt-2">Director: {movie.director}</p>
      <p className="movie-synopsis text-gray-300 pt-2">Synopsis: {movie.synopsis}</p>
      <div className="movie-trailer relative overflow-hidden pt-5 text-center" ref={movieTrailerRef}>
        {fetchFailed ? (
          <div className="p-4 w-full flex flex-wrap justify-center items-center content-center">
            <IonIcon icon={logoYoutube} className="youtube-icon"></IonIcon>
            <a href={`https://www.youtube.com/results?search_query=${encodeURIComponent(movie.title + " movie trailer")}`} target="_blank" rel="noopener noreferrer">Search on YouTube</a>
          </div>

        ) : trailerUrl ? (
          <YouTube videoId={trailerUrl.split('/embed/')[1]} opts={opts} onStateChange={onPlayerStateChange} onError={onTrailerError} className="w-full aspect-video" containerClassName="w-full" onReady={onPlayerReady}/>

        ) : (
          <div className="spinner">
            <Loading type="spinner" />
          </div>
        )}
      </div>
    </div>
  )
};

export default Movie;