import React from 'react';
import MovieList from './MovieList';
import Loading from './Loading';

const LikedMovies = ({ likedMovies }) => {
  return (
    <div className="movie-list-header flex flex-col items-center pt-10">
      <div className="current-mood pt-10 text-xl text-left w-full ml-2 text-sky-200">My Movies</div>
      {likedMovies && likedMovies.length === 0 &&
        <p className="text-sky-200 pt-5">
          Empty here! <br />Time to bookmark and build your ultimate watchlist!
        </p>}
      {likedMovies === "loading" && <Loading type="spinner" />}
      {likedMovies && likedMovies.length > 0 && likedMovies !== "loading" && <MovieList movies={likedMovies} />}
    </div>
  );
};

export default LikedMovies;